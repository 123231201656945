import React, { useState, useEffect } from "react";
import "./login.css";
import { Container, Box, TextField, Button } from '@material-ui/core';
// import {MuiAlert as Alert} from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from './../utils/string.js';
import Spinner from "./../shared/Spinner";
import { handleLoginResponse, handleResponse, _queryStringify } from "./../utils/xhr.js";
import Snackbar from "./../shared/Snackbar";
import Cookies from "js-cookie"
import { navigate } from "gatsby"
import theme from '../theme';
import Header from '../components/Header';
import CopyRight from '../components/CopyRight';
import Link from '../components/Link';
import riverImage from './../images/river.jpeg'
import { ContactsOutlined } from "@material-ui/icons";
import { Helmet } from "react-helmet"


const useStyles = makeStyles((theme) => ({
  textFields: {
    width: "400px",
    margin: "20px 0"
  },
  root: {
    width: '100%',
    marginTop: "20px"
  },
  container: {
    maxHeight: 440,
  },
  borderedTable: {
    width: "50%",
    margin: 20,
    "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)"
    },
  }
}));


function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    snackMessage: "Empty message",
    snackbarType: "",
  });
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState("login");
  const [successAlert, setSuccessAlert] = useState(false);

  const validStringField = (field, value, error_message="Field is required!") => {
    if (isEmpty(value)) {
      setErrors((errors) => {
        const tempErrors = {...errors,
                            [field]: error_message};
        return tempErrors;
      });
      return false;
    } else {
      setErrors((errors) => {
        const tempErrors = {...errors,
                            [field]: false};
        return tempErrors;
      });
      return true;
    }
  };

  const validLogin = () => {
    const validEmail = validStringField('email', email);
    const validPassword = validStringField('password', password);
    return (validEmail
            && validPassword);
  };

  const _verifyLogin = () => {
    setLoading(true);
    var params = {
      email,
      password
    };

    const requestOptions = {
      method: 'POST'
    };
    var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/login?${_queryStringify(params)}`

    fetch(url,
          requestOptions)
      .then(handleLoginResponse)
      .then(
        resp => {
          const jwt_token = resp["Authorization"];
          let cookie_expiry_time = new Date()
          cookie_expiry_time.setTime(cookie_expiry_time.getTime() + 3600 * 1000) // expires in 1 hour
          Cookies.set("access_token_cookie", jwt_token, {
            expires: cookie_expiry_time,
          })
          navigate('/app/users')
        //   localStorage.setItem('access_token', jwt_token);
          setLoading(false);
        },
        error => {
          setLoading(false);
          setSnackbarData((snackbarData) => ({
            snackMessage: "Invalid email/password!",
            snackbarType: "error",
          }));
          setOpen(true);
        });
  };

  const _resetPassword = () => {
    if (validStringField('email', email) === true) {
      setLoading(true);
      var params = {
        email
      };

      const requestOptions = {
        method: 'POST'
      };
      var url = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/reset-password?${_queryStringify(params)}`

      fetch(url, requestOptions)
        .then(handleResponse)
        .then(
          (resp) => {
            setLoading(false);
            setSnackbarData((snackbarData) => ({
              snackMessage: "Password has been sent to registered Email-id!",
              snackbarType: "success",
            }));
            setOpen(true);
          },
          (resp) => {
            setLoading(false);
            setSnackbarData((snackbarData) => ({
              snackMessage: resp,
              snackbarType: "error"
            }));
            setOpen(true);
            setSuccessAlert(false);
          });
    }
  };

  const handleLogin = () => {
    if (validLogin()) {
      _verifyLogin();
    }
  };

  const renderLogin = () => {
    return (
      <div className="login__form">
        <div className="login__heading">
          <h3>Login</h3>
        </div>

        <div className="login__inputs">
          <TextField
            error={errors['email'] ? true : null}
            id="email"
            label="Email"
            type="text"
            variant="outlined"
            value={email}
            onChange={e => setEmail(e.target.value)}
            classes={{root: classes.textFields}}
            helperText={errors['email'] ? errors['email'] : null}
            ></TextField>
          <TextField
            error={errors['password'] ? true : null}
            id="password"
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={e => setPassword(e.target.value)}
            classes={{root: classes.textFields}}
            helperText={errors['password'] ? errors['password'] : null}
            />
            <div style={{display: "flex", justifyContent: "flex-end", color: "blue", cursor:"pointer"}}
                 onClick={() => setStep("forgot-password")}>
              Forgot password?
            </div>
        </div>

        <button onClick={handleLogin}>Login</button>
        <Snackbar
          type={snackbarType}
          open={open}
          setOpen={setOpen}
          snackMessage={snackMessage}
          />
        {loading && <Spinner />}
      </div>
    );
  };

  const renderForgotPassword = () => {
    return (
      <div className="login__form">
        <div className="login__heading">
          <h3>Reset Password</h3>
        </div>

        {/* {successAlert ? <Alert severity="success">Password reset details are sent to your email ID!</Alert> : null} */}
        <div className="login__inputs">
          <TextField
            error={errors['email'] ? true : null}
            id="email"
            label="Email"
            type="text"
            variant="outlined"
            value={email}
            onChange={e => setEmail(e.target.value)}
            classes={{root: classes.textFields}}
            helperText={errors['email'] ? errors['email'] : null}
            ></TextField>
        </div>

        <button style={{background: "darkgray"}}
                onClick={() => setStep("login")}>
          Back</button> {' '}
        <button onClick={_resetPassword}>Reset</button>
        <Snackbar
          type={snackbarType}
          open={open}
          setOpen={setOpen}
          snackMessage={snackMessage}
          />
        {loading && <Spinner />}
      </div>
    );
  };

  const renderLoginStep = () => {
    if (step === 'login') {
      return renderLogin();
    }
    if (step === "forgot-password") {
      return renderForgotPassword();
    }
    return null;
  };

  const { snackbarType, snackMessage } = snackbarData;
  return (
    <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name='description' content='Namami Gange Dashboard!' />
          <meta name='owner' content='https://www.qcin.org/' />
          <meta name='developer' content='https://dataorc.in/' />
          <title>Login | Namami Gange Dashboard</title>
        </Helmet>
      {/* <ThemeProvider theme={theme}> */}

        <Header isSelectBar={false} height={null}/>

        <Box
            sx={{
            // backgroundColor: '#d3d5e5',
            // minHeight: '100%',
            pt: 5,
            pb: 15
            }}
            style={{backgroundImage: `url(${riverImage})`, backgroundSize: 'cover', objectFit: 'contain', height: "60%"}}
        >
            <Container maxWidth={false} >
                <div className="login">
                {renderLoginStep()}
                </div>
            </Container> 
        </Box>
            
        <div style={{display: "flex", justifyContent: "center"}}>
          <Link to="/" style={{marginRight: 10}}> Home </Link>
          <CopyRight />
        </div>
        
      {/* </ThemeProvider> */}
    </>
    
  );
}

export default Login;
